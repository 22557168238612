import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const LinkBox = styled.div`
    width: 165px;
    height:100px;
    display:inline-block;
    text-align:center;
    border: 1px dotted ${({ theme }) => theme.border};
    padding:5px;
    margin:5px;
    color: white;
    background-color: ${({ theme }) => theme.containerBg};
`

const HomeToolsContainer = ({ data }) => {
    return (
        <div className='d-flex'>
            {
                data.map((single, index) => {
                    return (
                        <Link className="nav-link" to={single.url} key={index}>
                            <LinkBox key={index}>
                                <img src={single.img} alt={single.title} height={"60%"} className="container-theme" />
                                <p className='fs-6'>{single.title}</p>
                            </LinkBox>
                        </Link>
                    )
                })
            }
        </div>
    )
}

export default HomeToolsContainer