import React, { useState, useRef } from 'react'
import Base from '../Base'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { API } from '../../backend';
import imgpdf from "../../assets/images/pdf.png"
import ServiceInfoCard from '../../components/ServiceInfoCard';
import FileContainer from '../../components/container/FileContainer';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';


export default function MergePdf() {
    const [files, setFiles] = useState([]);
    const [previews, updatePreviews] = useState(files)
    const [downloadMerge, setDownloadMerge] = useState({
        Url: "",
        display: false
    })

    const [displayPreview, setDisplayPreview] = useState(false)
    const [displaySubmitBtn, setDisplaySubmitBtn] = useState(true)
    const [displayprogressBar, setDisplayprogressBar] = useState(false)
    const [displayMergeBtn, setDisplayMergeBtn] = useState(false)
    const [displaySelectedFiles, setDisplaySelectedFiles] = useState(false)
    const inputFile = useRef("")
    var inputFileSelected = inputFile.current.files
    var fileType = []
    const [totalUpload, setTotalUpload] = useState(0)
    // console.log("Initial File and UpdatePreview", inputFileSelected, "and preview:", previews)

    const onSuccess = (savedFiles) => {
        setFiles(savedFiles)
        updatePreviews(savedFiles)
        setDisplayPreview(true)
        setDisplayMergeBtn(true)

    };

    const onInputChange = (e) => {
        setFiles(e.target.files)
        setDisplaySelectedFiles(true)

    };
    const config = {

        onUploadProgress: progressEvent => setTotalUpload((progressEvent.loaded / progressEvent.total) * 100)
    }



    const onSubmit = (e) => {

        e.preventDefault();
        if (files.length < 2) {
            toast.warning('Please select atleast two files to merge');
            return
        }
        for (let index = 0; index < files.length; index++) {
            if (files[index].type === "application/pdf") {

            } else if (files[index].type === "image/x-png") {
            } else if (files[index].type === "image/png") {
            } else if (files[index].type === "image/jpeg") {
            } else if (files[index].type === "image/jpg") {
            } else {
                toast.warning(`Please check the input file type. ${files[index].name} is not valid.`);
                return
            }

        }
        toast.success(`🎆 Upload Started!`);
        setDisplaySelectedFiles(false)
        setDisplaySubmitBtn(false)
        setDisplayprogressBar(true)
        const data = new FormData();

        for (let i = 0; i < files.length; i++) {
            data.append('files', files[i]);
        }

        axios.post(`${API}/upload`, data, config)
            .then((response) => {
                toast.success('🦄 Upload Successfully');
                onSuccess(response.data)


            })
            .catch(() => {
                toast.error('Upload Error. Please Retry')
            })
    };
    const mergePDF = () => {

        setDisplayprogressBar(false)
        const mergeData = new FormData();
        previews.forEach((item) => {
            mergeData.append('files', item.path);
        });
        axios.post(`${API}/mergepdf`, mergeData)
            .then((response) => {
                toast.success('🚀 Merged Successfully!');
                // console.log("merge response", response.data)
                setDownloadMerge({
                    display: true,
                    Url: response.data,
                })
            })
            .catch(() => {
                toast.error('Upload Error')
            })

    }
    const SortableItem = SortableElement(({ value }) => {
        return (
            <li>
                <div className="singlePreview">
                    <img style={value.mimetype === "application/pdf" ? { width: '90px', height: '120px', backgroundColor: 'white', padding: '20px' } : { width: '90px', height: '120px', backgroundColor: 'white', border: '2px solid black' }} src={value.mimetype === "application/pdf" ? imgpdf : `${API}/${value.filename}`} alt={value.originalname} />
                    <p className="originalFileName">{value.originalname}</p>
                </div>
            </li>
        )
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <ul style={{ 'display': 'flex', 'flexDirection': 'row', flexWrap: 'wrap' }}>
                {items.map((file, index) => (
                    <SortableItem key={`item-${file.filename}`} index={index} value={file} />
                ))}
            </ul>
        );
    });

    const handleOnDragEnd = ({ oldIndex, newIndex }) => {
        if (!newIndex) return;

        const items = Array.from(previews)
        const [reorderedItems] = items.splice(oldIndex, 1)
        items.splice(newIndex, 0, reorderedItems)

        updatePreviews(items)
    };
    const previewDisplay = () => {

        return (
            <div className="container">
                <h2 className="py-4">Rearrange files order and Merge</h2>
                <FileContainer>
                    {/*  */}
                    <SortableList axis="xy" items={previews} onSortEnd={handleOnDragEnd} />
                    {/*  */}
                </FileContainer>
            </div>
        )
    }

    const progressBar = () => {
        return (
            <div className="uploadProgress">
                <p>Uploading Files to the Cloud!</p>
                <progress id="progressBar" value={totalUpload} max="100" style={{ 'width': '300px' }}></progress><span>&nbsp;{totalUpload.toFixed(0)}%</span>
            </div>
        )
    }

    const supportedFiles = () => {
        return (
            <strong className="upload-area__tooltip text-info">
                Supported File(s)
                <span className="upload-area__tooltip-data">'.pdf'</span>
            </strong>
        )
    }

    const selectedFiles = () => {
        var array = []

        for (let index = 0; index < inputFileSelected.length; index++) {
            array[index] = inputFileSelected[index].name + "Size" + inputFileSelected[index].size;
            fileType[index] = inputFileSelected[index].type
        }

        return (
            <div className="container">
                <h2>Selected Files:</h2>
                <table className="table table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">image</th>
                            <th scope="col">File Name</th>
                            <th scope="col">Size</th>
                        </tr>
                    </thead>
                    <tbody>

                        {array.map((file, index) => {
                            return (

                                <tr key={index} >
                                    <th scope="row">{index + 1}</th>
                                    <td>{<img src={URL.createObjectURL(inputFileSelected[index])} width="100" max-height="100" alt="your_image" />}</td>
                                    <td>{file.split("Size")[0]}</td>
                                    <td>{((file.split("Size")[1]) / 1024).toFixed(2)}&nbsp;KB</td>
                                </tr>

                            )
                        })}

                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <Base documentTitle="Merge PDF and Images">

            <div className="container">
                <div className="jumbotron text-center">
                    <h2 className="display-4">Merge PDF!</h2>
                    <p className="lead">Merge multiple pdf(s) into single file. {supportedFiles()}</p>
                </div>
                <div className="text-center">

                </div>
                <form method="post" action="#" id="#" onSubmit={onSubmit}>

                    {/* check wether or not display iput file */}
                    {displaySubmitBtn === true ? <div className="form-group files">

                        <i className="fas fa-file-pdf uploadlogo" style={{ color: '#F1592A', fontSize: '48px' }}></i>

                        <div className="customFileChoose">
                            <button className="button m-auto" style={{ top: '20px' }}><i className="fas fa-plus-circle"></i>  &nbsp;Select Files</button>
                            <input type="file"
                                ref={inputFile}
                                onChange={onInputChange}
                                className="form-control"
                                accept="application/pdf,image/x-png,image/jpeg"
                                style={{ opacity: '0' }}
                                multiple />
                        </div>
                    </div> : <div className="mx-auto text-center">
                        <button className="button" onClick={() => window.location.reload()}>Start Again?</button>
                        <div className="form-group files" style={{ display: 'none' }}>

                            <i className="fas fa-file-pdf uploadlogo" style={{ color: '#F1592A', fontSize: '48px' }}></i>

                            <div className="customFileChoose">
                                <button className="button m-auto" style={{ top: '20px' }}><i className="fas fa-plus-circle"></i>  &nbsp;Select Files</button>
                                <input type="file"
                                    ref={inputFile}
                                    onChange={onInputChange}
                                    className="form-control"
                                    accept="application/pdf"
                                    style={{ opacity: '0' }}
                                    multiple />
                            </div>
                        </div>
                    </div>}
                    {/* check wether or not display iput file */}
                    <div className="container">

                        {displaySelectedFiles &&
                            selectedFiles()
                        }

                    </div>
                    {displayPreview === true ? previewDisplay() : null}
                    {displayprogressBar === true ? progressBar() : null}
                    {displaySubmitBtn === true ? <button className="button"><i className="fas fa-cloud-upload-alt"></i> &nbsp; Upload</button> : null}
                </form>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {displayMergeBtn === true ? <button className="button" onClick={() => (mergePDF())} >Merge</button> : null}


                    {downloadMerge.display === true && <a href={`${API}/` + downloadMerge.Url} download target='_blank' rel='noreferrer'><button type="button" className="button">Download Files</button></a>}
                </div>


            </div>
            <section>
                <div className="container  py-4">
                    <div className="row mx-auto" style={{ backgroundColor: "blueviolet" }}>
                        <div className="col-md-4 text-center p-5">
                            <ServiceInfoCard serviceLogo="far fa-file-pdf" heading="Easy and Quick tool to merge PDFs" bodyText="Our service allow you to quickly merge PDF, PNG, JPG files into a single PDF file in few simple clicks." />
                        </div>
                        <div className="col-md-4 text-center p-5">
                            <ServiceInfoCard serviceLogo="fas fa-shield-alt" heading="Merge PDF with Security" bodyText="Our PDF Merger service do not require you to signup. All the files uploaded to the server automatically deleted after 3 hours of merging." />
                        </div>
                        <div className="col-md-4  text-center p-5">
                            <ServiceInfoCard serviceLogo="fab fa-cloudversify" heading="Access this service from Anywhere" bodyText="Our service is web based which means you can access our tools and service from any device including Windows, Android,iOs, Linux or MacOS from anywhere." />
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer pauseOnFocusLoss="false" />
        </Base>

    )
}
