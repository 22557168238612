import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import { globalContext } from "../../context/GlobalContext";

const Signup = () => {
    const { isLoggedIn } = useContext(globalContext)
    let navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) navigate('/')
        document.title = "Signup - Tools"
    }, []);
    const { setIsLoggedIn, setAuthUser } = useContext(globalContext);


    const SignupSubmit = async (e) => {
        e.preventDefault();
        let firstname = ""
        let lastname = ""
        let email = ""
        let username = ""
        let password = ""
        firstname = e.target[0].value
        lastname = e.target[1].value
        email = e.target[2].value
        username = e.target[3].value
        password = e.target[4].value
        // console.log({ firstname, lastname, email, username: username, password: password })
        await axios.post(`${process.env.REACT_APP_BACKEND}/auth/signup`, { firstname, lastname, email, username: username, password: password })
            .then(({ data }) => {
                if (data.status === "error") {
                    toast.error(data.msg);
                    setIsLoggedIn(false);
                } else if (data.status === "success") {
                    toast.success(data.msg)
                    navigate('/login')
                }
            })
            .catch((err) => {
                setIsLoggedIn(false);
                console.log(err)
            })

    }

    return (
        <div className="overflow-x-hidden">
            {/* <div className="fixed bottom-0 left-0 right-0 z-40 px-4 py-3 text-center text-white bg-gray-800">
                Designed & Maintained By <a className="text-gray-200 underline" href="https://namankhare.com">Naman</a> at <a className="text-gray-200 underline" href="https://haxworld.net">HaxWorld Easytech Sol Pvt. Ltd.</a>
            </div> */}
            <section className="flex flex-col md:flex-row h-screen items-center">
                <div
                    className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
        flex items-center justify-center"
                >

                    <div className="w-full h-100 grid content-center">
                        <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12 text-black">
                            Register a New Account!
                        </h1>
                        <form className="mt-6" onSubmit={SignupSubmit}>
                            {/* firstname */}
                            <div className="my-2">
                                <label className="block text-gray-700">First Name</label>
                                <input
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    placeholder="Enter Your First Name"
                                    className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none text-black"
                                    autoFocus
                                    required
                                />
                            </div>

                            {/* lastname */}
                            <div className="my-2">
                                <label className="block text-gray-700">Last Name</label>
                                <input
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    placeholder="Enter Your Last Name"
                                    className="w-full  text-black px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                                    autoFocus
                                    required
                                />
                            </div>


                            {/* email */}
                            <div className="my-2">
                                <label className="block text-gray-700">Email Address</label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Enter Email Address"
                                    className="w-full  text-black px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                                    autoFocus
                                    required
                                />
                            </div>

                            {/* username */}
                            <div className="my-2">
                                <label className="block text-gray-700">Username</label>
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    placeholder="Enter Your Username"
                                    className="w-full  text-black px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                                    autoFocus
                                    required
                                />
                            </div>
                            {/* password */}
                            <div className="my-2">
                                <label className="block text-gray-700">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Enter Password"
                                    minLength="6"
                                    className="w-full text-black  px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500
                focus:bg-white focus:outline-none"
                                    required
                                />
                            </div>

                            <button
                                type="submit"
                                className="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
              px-4 py-3 mt-6"
                            >
                                Signup
                            </button>
                        </form>
                        <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
                        <button
                            type="button"
                            className="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg
              px-4 py-3"
                            onClick={() => { navigate('/login') }}
                        >
                            Login!
                        </button>
                    </div>
                </div>
                <div className="bg-indigo-600 hidden md:block w-full md:w-1/2 xl:w-2/3 h-screen">
                    <img
                        src="https://source.unsplash.com/user/wsanter"
                        alt=""
                        className="w-full h-full object-cover"
                    />
                </div>


            </section>
            <ToastContainer />
        </div>
    );
};

export default Signup;
