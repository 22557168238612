import React from 'react'
import Menu from './Menu'
import { Link } from 'react-router-dom'


const Base = ({
    title = "My Title",
    description = "My description",
    className = " p-4",
    documentTitle = "Basic Utility Website - HaxWorld",
    children
}) => {
    document.title = documentTitle + " - HaxWorld"
    return (
        <>
            <Menu />

            <div className="container-fluid py-4">

                <div className={className}>{children}</div>
            </div>
            <footer className="footer bg-dark mt-auto py-2 fixed-bottom position-relative">
                {/* <div className="container-fluid text-center py-3">
                    <h4 className="text-white">If you got any questions, feel free to reach out</h4>
                    <button className="btn btn-warning btn-lg">
                        contact us
                    </button>
                </div> */}
                <div className="container-fluid">
                    <div className="container d-flex justify-content-between ">
                        <span className="text-muted ">
                            A <span style={{ color: "#bb86fc", fontWeight: "bold" }}>HaxWorld</span> company by <span><a href="https://twitter.com/naman_khare" style={{ color: "#bb86fc" }}>Naman Khare</a></span>
                        </span>
                        <span className="text-muted ">
                            <Link className="nav-link" to="/privacy-policy">Privacy Policy</Link>
                        </span>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Base
