import React, { createContext, useState } from 'react'

import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../components/styles/GlobalStyles";
import { lightTheme, darkTheme } from "../components/styles/Themes"

const defaultColorMode = () => {
    if (
        localStorage.getItem("theme") === "dark" ||
        localStorage.getItem("theme") === "light"
    ) {
        return localStorage.getItem("theme");
    } else {
        return window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light';
    }
};

export const globalContext = createContext(null);

const userDetails = {
    id: "",
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    loginhistory: [],
    role: 0
};

const GlobalContext = ({ children }) => {
    const [theme, setTheme] = useState(defaultColorMode);
    const [authUser, setAuthUser] = useState(userDetails)
    const [userDetail, setUserDetail] = useState({
        name: ""
    })
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    return (
        <globalContext.Provider
            value={{
                theme,
                setTheme,
                isLoggedIn,
                setIsLoggedIn,
                authUser,
                setAuthUser,
                userDetail,
                setUserDetail
            }}
        >
            <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
                <GlobalStyles />
                {children}
            </ThemeProvider>
        </globalContext.Provider>
    )
}

export default GlobalContext