import React, { useState, useRef } from 'react'
import Base from '../Base'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { API } from '../../backend';
import imgpdf from "../../assets/images/pdf.png"
import ServiceInfoCard from '../../components/ServiceInfoCard';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import FileContainer from '../../components/container/FileContainer';



export default function MergePdf() {
    const [files, setFiles] = useState([]);
    const [previews, updatePreviews] = useState(files)
    const [downloadMerge, setDownloadMerge] = useState({
        Url: "",
        display: false,
        size: "",
        oldSize: ""
    })

    const [displayPreview, setDisplayPreview] = useState(false)
    const [displaySubmitBtn, setDisplaySubmitBtn] = useState(true)
    const [displayprogressBar, setDisplayprogressBar] = useState(false)
    const [displayMergeBtn, setDisplayMergeBtn] = useState(false)
    const [displaySelectedFiles, setDisplaySelectedFiles] = useState(false)
    const inputFile = useRef("")
    var inputFileSelected = inputFile.current.files
    var fileType = []
    const [totalUpload, setTotalUpload] = useState(0)

    const colorValue = useRef(0)
    const grayValue = useRef(0)
    const monoValue = useRef(0)
    const compatibility = useRef('')

    const [ColorState, setcolorState] = useState(150)
    const [GrayState, setgrayState] = useState(150)
    const [MonoState, setmonoState] = useState(150)
    const [Compatibility, setcompatibility] = useState(1.3)



    const onSuccess = (savedFiles) => {
        setFiles(savedFiles)
        updatePreviews(savedFiles)
        setDisplayPreview(true)
        setDisplayMergeBtn(true)
    };

    const onInputChange = (e) => {
        setFiles(e.target.files)
        setDisplaySelectedFiles(true)


    };
    const config = {

        onUploadProgress: progressEvent => setTotalUpload((progressEvent.loaded / progressEvent.total) * 100)
    }


    const onSubmit = (e) => {

        e.preventDefault();
        for (let index = 0; index < files.length; index++) {
            if (files[index].type !== "application/pdf") {
                toast.warning(`Please check the input file type. ${files[index].name} is not valid.`);
                return
            }

        }
        toast.success(`🎆 Upload Started!`);
        setDisplaySelectedFiles(false)
        setDisplaySubmitBtn(false)
        setDisplayprogressBar(true)
        const data = new FormData();

        data.append('files', files[0]);



        axios.post(`${API}/upload`, data, config)
            .then((response) => {
                toast.success('🦄 Upload Successfully');
                onSuccess(response.data)
            })
            .catch(() => {
                toast.error('Upload Error. Please Retry')
            })
    };

    const CompressOptions = () => {
        const consoleVal = () => {
            setcolorState(colorValue.current.value)
            setgrayState(grayValue.current.value)
            setmonoState(monoValue.current.value)
            setcompatibility(compatibility.current.value)
            // console.log(compatibility.current.value)
        }

        return (
            <div className="d-flex flex-wrap justify-content-between py-4 flex-column flex-md-row">
                <div>
                    <select className="form-select" aria-label="Select compatibility" ref={compatibility} onChange={consoleVal} defaultValue={'1.5'}>
                        <option value="0">Select Compatibility</option>
                        <option value="1.3">Acrobat 4.0</option>
                        <option value="1.4">Acrobat 5.0</option>
                        <option value="1.5">Acrobat 6.0</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="colorValue" className="form-label">Color Images(Quality): {ColorState} </label>
                    <input type="range" className="form-range" value={ColorState} min="72" max="300" id="colorValue" ref={colorValue} onChange={consoleVal} />
                </div>
                <div>
                    <label htmlFor="grayValue" className="form-label">Gray Images(Quality): {GrayState} </label>
                    <input type="range" className="form-range" value={GrayState} min="72" max="300" id="grayValue" ref={grayValue} onChange={consoleVal} />
                </div>
                <div>
                    <label htmlFor="monoValue" className="form-label">Mono Images(Quality): {MonoState} </label>
                    <input type="range" className="form-range" value={MonoState} min="72" max="300" id="monoValue" ref={monoValue} onChange={consoleVal} />
                </div>
            </div>
        )
    }
    const compressPdf = () => {

        setDisplayprogressBar(false)
        //

        //
        const mergeData = new FormData();
        previews.forEach((item) => {
            mergeData.append('file', item.path);
            mergeData.append('color', ColorState);
            mergeData.append('gray', GrayState);
            mergeData.append('mono', MonoState);
            mergeData.append('comp', Compatibility);
        });

        //Check if there are any previous pending requests

        axios.post(`${API}/compresspdf`, mergeData)
            .then((response) => {
                toast.success('🚀 Compressed Successfully!');
                console.log("merge response", response.data)
                setDownloadMerge({
                    display: true,
                    Url: response.data.path,
                    size: response.data.size
                })
            })
            .catch(() => {
                toast.error('Cannot compress this file. Please try again!')
            })

    }

    const SortableItem = SortableElement(({ value }) => {
        return (
            <li>
                <div className="singlePreview">
                    <img style={value.mimetype === "application/pdf" ? { width: '90px', height: '120px', backgroundColor: 'white', padding: '20px' } : { width: '90px', height: '120px', backgroundColor: 'white', border: '2px solid black' }} src={value.mimetype === "application/pdf" ? imgpdf : `${API}/${value.filename}`} alt={value.originalname} />
                    <p className="originalFileName">{value.originalname}</p>
                </div>
            </li>
        )
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <ul style={{ 'display': 'flex', 'flexDirection': 'row', flexWrap: 'wrap' }}>
                {items.map((file, index) => (
                    <SortableItem key={`item-${file.filename}`} index={index} value={file} />
                ))}
            </ul>
        );
    });

    const handleOnDragEnd = ({ oldIndex, newIndex }) => {
        if (!newIndex) return;

        const items = Array.from(previews)
        const [reorderedItems] = items.splice(oldIndex, 1)
        items.splice(newIndex, 0, reorderedItems)

        updatePreviews(items)
    };
    const previewDisplay = () => {

        return (
            <div className="container">
                <h2 className="py-4">Compress Pdf files</h2>
                <FileContainer>
                    {/*  */}
                    <SortableList axis="xy" items={previews} onSortEnd={handleOnDragEnd} />
                    {/*  */}
                </FileContainer>
            </div>
        )
    }
    // const previewDisplay = () => {

    //     return (
    //         <div className="container">
    //             <h2 className="py-4">Compress Pdf files</h2>
    //             <div className="row">
    //                 {/*  */}
    //                 <DragDropContext onDragEnd={handleOnDragEnd}>
    //                     <Droppable droppableId="previews" direction="horizontal">
    //                         {(provided) => (
    //                             <ul style={{ 'display': 'flex', 'flexDirection': 'row' }} {...provided.droppableProps} ref={provided.innerRef} >
    //                                 {previews.map((file, index) => {
    //                                     return (

    //                                         <Draggable key={file.filename} draggableId={file.filename} index={index} >
    //                                             {(provided) => (
    //                                                 <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
    //                                                     <div className="singlePreview">
    //                                                         <img style={file.mimetype === "application/pdf" ? { width: '90px', height: '120px', backgroundColor: 'white', padding: '20px' } : { width: '90px', height: '120px', backgroundColor: 'white', border: '2px solid black' }} src={file.mimetype === "application/pdf" ? imgpdf : `${API}/${file.filename}`} alt={file.originalname} />
    //                                                         <p className="originalFileName">{file.originalname}</p>
    //                                                     </div>
    //                                                 </li>
    //                                             )}
    //                                         </Draggable>
    //                                     )
    //                                 })}
    //                                 {provided.placeholder}
    //                             </ul>
    //                         )}



    //                     </Droppable>
    //                 </DragDropContext>
    //                 {/*  */}
    //             </div>
    //         </div>
    //     )
    // }

    const progressBar = () => {
        return (
            <div className="uploadProgress">
                <p>Uploading Files to the Cloud!</p>
                <progress id="progressBar" value={totalUpload} max="100" style={{ 'width': '300px' }}></progress><span>&nbsp;{totalUpload.toFixed(0)}%</span>
            </div>
        )
    }

    const supportedFiles = () => {
        return (
            <strong className="upload-area__tooltip text-info">
                Supported File(s)
                <span className="upload-area__tooltip-data"> '.pdf'</span>
            </strong>
        )
    }

    const selectedFiles = () => {
        var array = []


        for (let index = 0; index < inputFileSelected.length; index++) {
            array[index] = inputFileSelected[index].name + "Size" + inputFileSelected[index].size;
            fileType[index] = inputFileSelected[index].type

        }

        return (
            <div className="container">
                <h2>Selected Files:</h2>
                <table className="table table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">File Name</th>
                            <th scope="col">Size</th>
                        </tr>
                    </thead>
                    <tbody>

                        {array.map((file, index) => {
                            return (

                                <tr key={index} >
                                    <th scope="row">{index + 1}</th>
                                    <td>{file.split("Size")[0]}</td>
                                    <td>{((file.split("Size")[1]) / 1024).toFixed(2)}&nbsp;KB</td>
                                </tr>

                            )
                        })}

                    </tbody>
                </table>
            </div>
        )
    }

    const compareSize = () => {
        const finalSize = parseFloat((downloadMerge.size / 1024).toFixed(2))
        const initialSize = parseFloat((files[0].size / 1024).toFixed(2))

        if (finalSize === initialSize || finalSize > initialSize) {
            toast.warning(`Already Optimized!`)
            return (
                `File Already Optimized!`
            )
        } else {
            return (
                <div className="container">
                    <p> Size after compressing: {finalSize}KB </p>
                </div>
            )
        }
    }

    return (
        <Base documentTitle="Compress PDF file size">

            <div className="container">
                <div className="jumbotron text-center">
                    <h2 className="display-4">Compress PDF!</h2>
                    <p className="lead">Compress or Reduce PDF file size. {supportedFiles()}</p>
                </div>
                <div className="text-center">

                </div>
                <form method="post" action="#" id="#" onSubmit={onSubmit}>

                    {/* check wether or not display iput file */}
                    {displaySubmitBtn === true ? <><div className="form-group files">

                        <i className="fas fa-file-pdf uploadlogo" style={{ color: '#F1592A', fontSize: '48px' }}></i>

                        <div className="customFileChoose">
                            <button className="button m-auto" style={{ top: '20px' }}><i className="fas fa-plus-circle"></i>  &nbsp;Select File</button>
                            <input type="file"
                                ref={inputFile}
                                onChange={onInputChange}
                                className="form-control"
                                accept="application/pdf,image/x-png,image/jpeg"
                                style={{ opacity: '0' }}
                            />
                        </div>

                    </div>
                        {CompressOptions()}
                    </>


                        : <div className="mx-auto text-center">
                            <button className="button" onClick={() => window.location.reload()}>Start Again?</button>
                            <div className="form-group files" style={{ display: 'none' }}>

                                <i className="fas fa-file-pdf uploadlogo" style={{ color: '#F1592A', fontSize: '48px' }}></i>

                                <div className="customFileChoose">
                                    <button className="button m-auto" style={{ top: '20px' }}><i className="fas fa-plus-circle"></i>  &nbsp;Select File</button>
                                    <input type="file"
                                        ref={inputFile}
                                        onChange={onInputChange}
                                        className="form-control"
                                        accept="application/pdf,image/x-png,image/jpeg"
                                        style={{ opacity: '0' }}
                                    />
                                </div>
                            </div>
                        </div>}
                    {/* check wether or not display iput file */}
                    <div className="container">

                        {displaySelectedFiles &&
                            selectedFiles()
                        }

                    </div>
                    {displayPreview === true ? previewDisplay() : null}
                    {displayprogressBar === true ? progressBar() : null}
                    {displaySubmitBtn === true ? <button className="button"><i className="fas fa-cloud-upload-alt"></i> &nbsp; Upload</button> : null}
                </form>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {displayMergeBtn === true ? <button className="button" onClick={() => (compressPdf())} >Compress Files</button> : null}


                    {/* {downloadMerge.display === true  && <a href={`${API}/`+downloadMerge.Url} download target='_blank' rel='noreferrer'><button type="button" className="button">Download Files</button></a>} */}
                    {downloadMerge.display === true && <div>{compareSize()}</div>}

                    {downloadMerge.display === true && <a href={`${API}/${downloadMerge.Url}`} download target='_blank' rel='noreferrer'><button type="button" className="button">Download Files</button></a>}


                </div>


            </div>
            <section>
                <div className="container  py-4">
                    <div className="row mx-auto" style={{ backgroundColor: "blueviolet" }}>
                        <div className="col-md-4 text-center p-5">
                            <ServiceInfoCard serviceLogo="far fa-file-pdf" heading="Easy and Quick tool to Compress PDFs" bodyText="Our service allow you to quickly compress PDF file into a smaller PDF file size in few simple clicks." />
                        </div>
                        <div className="col-md-4 text-center p-5">
                            <ServiceInfoCard serviceLogo="fas fa-shield-alt" heading="Compress PDF with Security" bodyText="Our PDF Compress service do not require you to signup. All the files uploaded to the server automatically deleted after 3 hours of compressing." />
                        </div>
                        <div className="col-md-4  text-center p-5">
                            <ServiceInfoCard serviceLogo="fab fa-cloudversify" heading="Access this service from Anywhere" bodyText="Our service is web based which means you can access our tools and service from any device including Windows, Android,iOs, Linux or MacOS from anywhere." />
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </Base>

    )
}
