export const lightTheme = {
    body: '#FFFFFF',
    text: '#363537',
    border: '#121212',
    background: '#363537',
    filter: 'invert(0)',
    containerBg: '#FFFFFF'

}
export const darkTheme = {
    body: '#000000',
    text: '#FAFAFA',
    border: '#FFFFFF',
    background: '#999',
    filter: 'invert(1)',
    containerBg: '#121212'
}
