import React, { useEffect, useRef, useState } from 'react'
import Base from '../../Base'
import YouTube from 'react-youtube';
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import getVideoId from 'get-video-id';
import { globalContext } from '../../../context/GlobalContext';
import { useContext } from 'react';
import { SOCKET_URL } from '../../../backend';

const YTRoom = () => {
    const { userDetail } = useContext(globalContext);
    const [socket, setSocket] = useState(null);
    const [eventState, setEventState] = useState(false);
    const playerRef = useRef(null)
    const urlRef = useRef(null)
    const [videoId, setVideoId] = useState("dQw4w9WgXcQ")

    useEffect(() => {

        const socket = io(SOCKET_URL);
        setSocket(socket);
        console.log(`Connecting socket...`);
        socket.on("connect", () => {
            console.log("connect!!");
            socket.emit("join-room", "test", uuidv4());
        });
        socket.on("reconnect", () => {
            console.log("reconnect!!");
            // socket.emit("join-room", room, username());
        });
        socket.on("defaultdata", function (data) {
            //get Default Editor Value collab 
            // console.log("defaultdata", data);
            playerRef.current.internalPlayer.seekTo(data.time)
        });
        socket.on("videoUrl", function ({ data }) {
            //get Default Editor Value collab 
            setVideoId(data.id);
            playerRef.current.internalPlayer.seekTo(0)
        });
        socket.on("connected", async function (data) {
            let currTime = await playerRef.current.internalPlayer.getCurrentTime()
            //Connect New Client Event
            // console.log("connected!: ", currTime);
            let sendCurrentData = {
                status: "connected",
                time: currTime
            }
            socket.emit("defaultdata", sendCurrentData);
        });
        socket.on("onStateChange", function (data) {
            // console.log(document.getElementById('videoid'))
            playerRef.current.internalPlayer.seekTo(data.currTime)
            setEventState(true);
        });
        socket.on("onPlaybackRateChange", function ({ data }) {
            // console.log(document.getElementById('videoid'))
            // playerRef.current.internalPlayer.seekTo(data.currTime)
            // setPlaybackRate
            console.log(data)
            playerRef.current.internalPlayer.setPlaybackRate(data.playback)
        });
        socket.on("status", function ({ data }) {
            // console.log(data)
            if (data.value === "play") {
                playerRef.current.internalPlayer.playVideo();
            }
            if (data.value === "pause") {
                playerRef.current.internalPlayer.pauseVideo();
            }
            // playerRef.current.internalPlayer.seekTo(data.currTime)
        });
        return () => {
            if (socket) {
                console.log(`disconnecting socket...`);
                socket.disconnect();
            }
        };
        // eslint-disable-next-line
    }, []);

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            'origin': 'http://localhost:3000',
            // 'controls': 0,
            'autohide': 1,
            'wmode': 'opaque',
        },
    };
    const onReady = (event) => {
        // access to player in all event handlers via event.target
        // console.log(event.target)
        event.target.pauseVideo();
        setEventState(event.target);
    }
    const onPlay = (event) => {
        event.target.playVideo();
        socket.emit("status", {
            data: { value: "play" },
        });
    }
    const onPause = (event) => {
        event.target.pauseVideo();
        socket.emit("status", {
            data: { value: "pause" },
        });
    }
    const onStateChange = (event) => {
        console.log(event.target)
        // if (eventState === false) {
        //     socket.emit("onStateChange", {
        //         data: { currTime: event.target.getCurrentTime() },
        //     });
        //     setEventState(true);
        // } else {
        //     setEventState(false)
        // }
    }
    const onPlaybackRateChange = (event) => {
        // console.log(event.target.getPlayerState())
        socket.emit("onPlaybackRateChange", {
            data: { playback: event.target.getPlaybackRate() },
        });
    }

    const watchVideo = () => {
        const { id } = getVideoId(urlRef.current.value.toString());
        setVideoId(id);
        socket.emit("videoUrl", {
            data: { id: id },
        });
        playerRef.current.internalPlayer.seekTo(0)
    }
    return (
        <Base documentTitle='your room'>
            <div className="jumbotron text-center">
                <h2 className="display-4 py-2">Youtube Watch Party!</h2>
            </div>
            <div className="input-group mb-3 w-75 m-auto">
                <span className='align-self-center px-1'>Please enter your URL: </span>
                <input type="text" placeholder='Enter Youtube URL link' ref={urlRef} className="form-control " />
                <button onClick={watchVideo} className="btn btn-outline-primary" type="button" id="button-addon2">Watch!</button>
            </div>

            {videoId &&
                <YouTube
                    videoId={videoId}                  // defaults -> ''
                    // id={"videoid"}                       // defaults -> ''
                    className={"d-flex justify-content-center"}                // defaults -> ''
                    //   iframeClassName={string}          // defaults -> ''
                    //   style={object}                    // defaults -> {}
                    //   title={string}                    // defaults -> ''
                    //   loading={string}                  // defaults -> undefined
                    opts={opts}                        // defaults -> {}
                    onReady={onReady}                    // defaults -> noop
                    onPlay={onPlay}                     // defaults -> noop
                    onPause={onPause}                    // defaults -> noop
                    //   onEnd={func}                      // defaults -> noop
                    //   onError={func}                    // defaults -> noop
                    onStateChange={onStateChange}              // defaults -> noop
                    onPlaybackRateChange={onPlaybackRateChange}       // defaults -> noop
                    //   onPlaybackQualityChange={func}    // defaults -> noop
                    ref={playerRef}
                />
            }

            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/pI5wEVuAwY8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
        </Base>
    )
}

export default YTRoom