import React, { useState } from 'react'
import Base from '../Base'
import PdfViewer from '../../components/pdfviewer/pdfviewer'

const EditPdf = () => {
    const [first, setfirst] = useState(null);

    const uploadPdf = (e) => {
        const files = e.currentTarget.files[0]
        setfirst(files)
    };

    const supportedFiles = () => {
        return (
            <strong className="upload-area__tooltip text-info">
                Supported File(s)
                <span className="upload-area__tooltip-data">'.pdf'</span>
            </strong>
        )
    }

    const handleReset = () => {
        setfirst(null);
    }
    return (
        <Base documentTitle="View PDF files">
            {
                first ? <div className='d-flex flex-column'> <PdfViewer url={first} />  <button className="button" onClick={handleReset}>View Another File?</button>
                </div>
                    : <div className="container">
                        <div className="jumbotron text-center">
                            <h2 className="display-4">View PDF</h2>
                            <p className="lead">Explore your Pdf without internet. {supportedFiles()}</p>
                        </div>
                        <div className="form-group files">
                            <i className="fas fa-file-pdf uploadlogo" style={{ color: '#F1592A', fontSize: '48px' }}></i>

                            <div className="customFileChoose">
                                <button className="button m-auto" style={{ top: '20px' }}><i className="fas fa-plus-circle"></i>  &nbsp;Select Files</button>
                                <input type="file"
                                    onChange={uploadPdf}
                                    className="form-control"
                                    accept="application/pdf,image/x-png,image/jpeg"
                                    style={{ opacity: '0' }}
                                    multiple />
                            </div>
                        </div>


                    </div>
            }
        </Base>
    )
}

export default EditPdf
