import React from 'react'
import Base from './Base'
import 'react-toastify/dist/ReactToastify.css';
import Hero from '../components/Hero';


export default function Home() {


    return (
        <Base>
            <div className="container">
                <div className="container fluid">
                    <div className="row">
                        <h2 className='d-flex justify-content-center fs-1 fwt-5'>HaxWorld Tools</h2>
                        <p className="d-flex justify-content-center">Welcome to HaxWorld! Head over to the Merge PDF page to use the tool.  You can merge or combine two or more pdf(s), png(s) or jpg(s) into a single pdf file. Or you can compress or reduce size of existing pdf.</p>
                        <Hero />
                    </div>
                </div>
            </div>
        </Base>

    )
}
