import React from 'react'

const ServiceInfoCard = ({
    serviceLogo = "",
    heading = "",
    bodyText = ""
}) => {
    return (
        <div>
            <i className={`fabIcon ${serviceLogo}`} style={{ fontSize: '25px', paddingBottom: '10px' }}></i>
            {/* <img src={serviceLogo} style={{filter: "invert(1)", paddingBottom: "10px"}} alt={serviceLogo} /> */}
            <p style={{ fontWeight: 'bold', color: "var(--text-white)" }}>{heading}</p>
            <p style={{ color: "var(--text-white)" }}>{bodyText}</p>
        </div>
    )
}

export default ServiceInfoCard
