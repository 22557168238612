import React from 'react';
import Routes from './Routes'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "./styles.css";
import { BrowserRouter as Router } from 'react-router-dom'
import GlobalContext from './context/GlobalContext';
ReactGA.initialize('UA-69300753-1');
ReactGA.pageview(window.location.pathname + window.location.search);



ReactDOM.createRoot(document.getElementById('root')).render(
  <GlobalContext>
    <Router>
      <Routes />
    </Router>
  </GlobalContext>
  ,
)
// ReactDOM.createRoot(document.getElementById('root')).render(
//   <React.StrictMode>
//     <Routes />
//   </React.StrictMode>,
// )
