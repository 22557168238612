import React from 'react'
import Base from '../Base'
// import axios from 'axios';
// import { API } from '../../backend';


const YTDownload = () => {
    return (
        <Base documentTitle="Compress PDF file size">

            <div className="container">
                <div className="jumbotron text-center  py-4">
                    <h2 className="display-4">YouTube Video Downloder</h2>
                    <p className="lead">Download YouTube videos, audios in different quality available.</p>
                </div>
                <div className="text-center">
                    <form className="d-flex flex-row justify-content-center">
                        <input className="form-control w-50  mx-2" type="text" placeholder="Enter YouTube URL here" aria-label="Youtube URL" />
                        <button className='btn btn-primary mx-2 px-4'>Search</button>
                    </form>
                </div>
            </div>
        </Base>
    )
}

export default YTDownload
