import React from 'react'
import styled from 'styled-components'

const FilesContainerDiv = styled.div`
    background: rgba(0,0,0,0.1);
    border: 1px solid black;
    border-radius: 18px;
    padding-top: 18px; 
`
const FileContainer = ({ children }) => {
    return (
        <FilesContainerDiv className='row'>
            {children}
        </FilesContainerDiv>
    )
}

export default FileContainer