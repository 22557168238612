import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument, StandardFonts } from 'pdf-lib';

import 'react-pdf/dist/cjs/Page/AnnotationLayer.css'; import 'react-pdf/dist/cjs/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function PdfViewer({ url }) {
    const [file, setFile] = useState(null);
    const [pdfBytes, setPdfBytes] = useState(null);
    const [selectedText, setSelectedText] = useState('');
    const [newText, setNewText] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const canvasRef = useRef(null);

    let dragTarget = null;
    let startX = null;
    let startY = null;
    let ctx = null;
    let pdf_image = "";

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const goToPrevPage = () =>
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    const goToNextPage = () =>
        setPageNumber(
            pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
        );

    function handleTextLayerRender(textItems) {
        // Handle text layer render
        console.log("textItems: ", textItems)
    }


    function handleNewTextChange(event) {
        console.log("handleNewTextChange: ", event.target.value)
        setNewText(event.target.value);
    }

    useEffect(() => {
        setFile(url);
    }, [url]);

    const registerKeyPress = useCallback((e) => {
        // setRandom(Math.random());
        // setKey(e.key);
        console.log(e.key)
        if (e.key === "ArrowRight") {
            setPageNumber(
                pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
            );
        } else if (e.key === "ArrowLeft") {
            setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
        }
        // "ArrowRight", 
        // "ArrowLeft"
    }, [numPages, pageNumber]);

    useEffect(() => {
        window.addEventListener("keydown", registerKeyPress);
    }, [registerKeyPress]);

    async function editPdf(file) {
        // Load the PDF file
        const existingPdfBytes = await file.arrayBuffer();

        // Load the PDF document using pdf-lib
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        // Get the first page of the PDF document
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        // // Get the existing text object of the first page
        const textObject = await firstPage.getTextContent();
        console.log(textObject)


        const { width, height } = firstPage.getSize();
        const textSize = 24;
        const text = 'Hello, World!';
        // const { x, y } = pages.getViewport({ scale: 1 }).convertToPdfPoint(10, height - 10);

        const options = {
            color: '#000000',
            font,
            size: textSize,
        };

        firstPage.drawText(text, {
            x: 122,
            y: 445,
            options,
        });

        // Serialize the PDF document
        const pdfBytes = await pdfDoc.save();

        // Download the new PDF file
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'edited.pdf';
        link.click();
    }

    const onPageClick = (e) => {
        console.log(e, "sdf")
    }

    let shapes = []
    let current_shape_index = 0;
    let is_dragging = false
    shapes.push({ x: 0, y: 0, w: 100, h: 50, color: 'blue', isDragging: false }, { x: 10, y: 0, w: 100, h: 50, color: 'red', isDragging: false })

    const is_mouse_in_shape = (x, y, shape) => {
        let shape_left = shape.x
        let shape_right = shape.x + shape.w
        let shape_top = shape.y
        let shape_bottom = shape.y + shape.h

        // console.log(shape_left, shape_right, shape_top, shape_bottom) // 0 100 0 50
        // console.log(x, y) // x=452 y=147 last


        if (x > shape_left && x < shape_right && y > shape_top && y < shape_bottom) {
            console.log("true")
            return true;
        } else {
            console.log("not true")
        }

    }


    const handleMouseDown = (event) => {
        if (typeof pdf_image == "string") {
            saveInitialCanvas();
        }
        event.preventDefault();
        event.stopPropagation();
        // setMouseDown(true);
        let e = event.nativeEvent
        // const canvas = canvasRef.current; 
        // canvas.width = 
        var rect = canvasRef.current.getBoundingClientRect();
        var widthScale = canvasRef.current.width / rect.width;
        var heightScale = canvasRef.current.height / rect.height;
        let startX = parseInt(e.clientX - rect.left) * widthScale;
        let startY = parseInt(e.clientY - rect.top) * heightScale;
        // console.log(startX, startY)
        let index = 0;
        for (let shape of shapes) {
            if (is_mouse_in_shape(startX, startY, shape)) {
                console.log("yes")
                current_shape_index = index;
                is_dragging = true;
                shape.isDragging = true;
                return;
            } else {
                console.log("no")
            }
            index++;
        }

    };
    const handleMouseUp = (event) => {
        // setMouseDown(true);
        let e = event.nativeEvent
        if (!is_dragging) {
            return;
        }
        event.preventDefault();
        is_dragging = false;
        for (var i = 0; i < shapes.length; i++) {
            shapes[i].isDragging = false;
        }
    };
    // const handleMouseOut = (event) => {
    //     // setMouseDown(true);
    //     let e = event.nativeEvent
    //     if (!is_dragging) {
    //         return;
    //     }
    //     event.preventDefault();
    //     is_dragging = false;
    // };
    // const handleSavePdf = () => savePdf(allPageAttachments);
    const handleMouseMove = (event) => {

        // console.log(event.nativeEvent)
        if (!is_dragging) {
            return;
        }
        else {
            event.preventDefault();
            var rect = canvasRef.current.getBoundingClientRect();
            var widthScale = canvasRef.current.width / rect.width;
            var heightScale = canvasRef.current.height / rect.height;
            let mouseX = parseInt(event.clientX - rect.left) * widthScale;
            let mouseY = parseInt(event.clientY - rect.top) * heightScale;

            let dx = mouseX - startX
            let dy = mouseY - startY

            // let current_shape = shapes[current_shape_index];
            // current_shape.x += dx;
            // current_shape.y += dy;

            for (var i = 0; i < shapes.length; i++) {
                var current_shape = shapes[i];
                if (current_shape.isDragging) {
                    current_shape.x += dx;
                    current_shape.y += dy;
                }
            }

            drawShapes();
            startX = mouseX;
            startY = mouseY;
        }

    };

    const saveInitialCanvas = () => {
        if (canvasRef.current.getContext) {
            var canvasPic = new Image();
            canvasPic.src = canvasRef.current.toDataURL();
            pdf_image = canvasPic;
        }
    };
    const drawShapes = () => {
        var rect = canvasRef.current.getBoundingClientRect();
        // ctx.clearRect(0, 0, rect.width, rect.height);
        // console.log(pdf_image)
        ctx.drawImage(pdf_image, 0, 0);
        // renderPage(currentPage, pdfRef);
        // boxes.map(info => drawFillRect(info));
        for (let shape of shapes) {
            // ctx.beginPath();
            ctx.fillStyle = shape.color;
            ctx.fillRect(shape.x, shape.y, shape.w, shape.h);

        }

    }
    const addBox = () => {
        if (typeof pdf_image == "string") {
            saveInitialCanvas();
        }
        const canvas = canvasRef.current;
        ctx = canvas.getContext('2d');
        drawShapes()
    }


    function onRenderSuccess(e) {
        // setCanvasContext(canvas.getContext('2d'));
        const importPDFCanvas = document.querySelector('.react-pdf__Page__canvas');
        // const pdfAsImageSrc = importPDFCanvas.toDataURL();
        canvasRef.current = importPDFCanvas
    }

    const onChangePageNumber = (e) => {
        // console.log(e.target.value, parseInt(e.target.value))
        (parseInt(e.target.value) > numPages) ? setPageNumber(parseInt(numPages)) : setPageNumber(parseInt(e.target.value))


    }

    return (
        <div className='d-flex justify-content-center'>
            {/* <button onClick={addBox}>add box</button> */}

            {/* {numPages && (
                <button onClick={() => editPdf(file)}>Edit PDF</button>
            )} */}
            {file && (
                <div>
                    <div className='d-flex'>
                        <button onClick={goToPrevPage} className='btn btn-primary rounded-circle my-auto mx-2 round-dimension'><i className="fas fa-chevron-left"></i></button>
                        <Document
                            file={file}
                            noData={console.log("No Data was found")}
                            onLoadError={(e) => {
                                console.log("CALLED", e);
                            }}
                            onLoadProgress={() => {
                                console.log("PRGREOSS");
                            }}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >

                            <Page
                                onItemClick={onPageClick}
                                pageNumber={pageNumber}
                                onTextLayerRender={handleTextLayerRender}
                                onRenderSuccess={onRenderSuccess}
                                renderTextLayer={true}
                            // onMouseDown={handleMouseDown}
                            // onMouseMove={handleMouseMove}
                            // onMouseUp={handleMouseUp}
                            // onMouseOut={handleMouseOut}

                            />
                        </Document>

                        <button onClick={goToNextPage} className='btn btn-primary rounded-circle my-auto mx-2 round-dimension'><i className="fas fa-chevron-right"></i></button>
                        {/* <div>
                        <label htmlFor="new-text-input">New Text:</label>
                        <input type="text" id="new-text-input" value={newText} onChange={handleNewTextChange} />
                    </div> */}
                    </div>
                    <div>
                        <div className='d-flex justify-content-center'>
                            <span>Page No. {pageNumber} of {numPages}</span>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <span>Go to page no: </span>
                            <input max={numPages} className='rounded' style={{ width: "50px" }} type="number" placeholder={pageNumber} onChange={onChangePageNumber} />
                        </div>
                    </div>


                </div>

            )
            }
        </div >
    );
} 