import React from 'react'
import HomeToolsContainer from './container/HomeToolsContainer'
import compressImg from '../assets/images/icons/img-compression.png'
import mergeImg from '../assets/images/icons/img-merge.png'
import yticon from '../assets/svg/yt-icon.svg'

let demoData = [
    {
        img: mergeImg,
        title: "Merge PDF",
        url: "/mergepdf"
    },
    {
        img: compressImg,
        title: "Compress PDF",
        url: "/compresspdf"
    },
    {
        img: compressImg,
        title: "View PDF",
        url: "/editpdf"
    },
    {
        img: yticon,
        title: "YT Room",
        url: "/syncyt"
    },
]

const Hero = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    {/*  */}
                    <HomeToolsContainer data={demoData} />
                </div>
            </div>
        </>

    )
}

export default Hero
