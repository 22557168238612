import { createGlobalStyle } from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    /* font-family: Tahoma, Helvetica, Arial, Roboto, sans-serif; */
    font-family: 'Open Sans', sans-serif;

    transition: all 0.50s ease-in-out;
  }
  h1,h2,h3,h4,h5,h6,p{
    color: ${({ theme }) => theme.text};
  
  }
  .container-theme{
    filter: ${({ theme }) => theme.filter};
  }
`
