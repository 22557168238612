import React, { useContext, useEffect } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import Home from './core/Home'
import MergePdf from './core/pages/MergePdf'
import CompressPdf from './core/pages/CompressPdf'
import PrivacyPolicy from './core/pages/PrivacyPolicy'
import YTDownload from './core/pages/YTDownload'
import { globalContext } from './context/GlobalContext'
import EditPdf from './core/pages/EditPdf'
import SyncYoutube from './core/pages/SyncYoutube/SyncYoutube'
import YTRoom from './core/pages/SyncYoutube/YTRoom'
import { v4 as uuidv4 } from "uuid";
import Login from './core/pages/Login'
import Signup from './core/pages/Signup'
import axios from 'axios'

const AppRoutes = () => {

    let navigate = useNavigate();
    const { setIsLoggedIn, setAuthUser } = useContext(globalContext);
    async function refreshAuthState() {
        console.log("chala")
        let getRefreshToken = localStorage.getItem("refreshtoken");
        if (getRefreshToken) {
            await axios
                .post(
                    `${process.env.REACT_APP_BACKEND}/auth/refreshauthstate`,
                    { refreshToken: getRefreshToken },
                    {
                        withCredentials: true,
                        headers: {
                            "Content-type": "application/json",
                            Accept: "application/json",
                        },
                    }
                )
                .then(({ data }) => {
                    if (data.status === "success") {
                        setIsLoggedIn(true);
                        setAuthUser({
                            id: data.id,
                            firstname: data.firstname || "",
                            lastname: data.lastname || "",
                            username: data.username || "",
                            email: data.email || "",
                            role: data.role || 0,
                        });
                    } else {
                        localStorage.removeItem('token')
                        localStorage.removeItem('name')
                        localStorage.removeItem('refreshtoken')
                        setIsLoggedIn(false);
                        setAuthUser({
                            id: "",
                            firstname: "",
                            lastname: "",
                            username: "",
                            email: "",
                            role: 0,
                        });
                        navigate("/");
                    }
                })
                .catch((err) => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('name')
                    localStorage.removeItem('refreshtoken')
                });
        }
    }
    useEffect(() => {
        refreshAuthState();
    }, []);

    return (
        // global context provider
        <Routes>
            <Route exact path="" element={<Home />} />
            <Route exact path="mergepdf" element={<MergePdf />} />
            <Route exact path="compresspdf" element={<CompressPdf />} />
            <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="ytdownload" element={<YTDownload />} />
            <Route exact path="editpdf" element={<EditPdf />} />
            <Route exact path="syncyt" element={<SyncYoutube />} />
            <Route exact path="ytroom" element={<Navigate replace to="/syncyt" />} />
            <Route exact path="ytroom/:room" element={<YTRoom />} />
            <Route
                path="join"
                element={<Navigate replace to={`/ytroom/${uuidv4()}`} />}
            />
            <Route exact path="login" element={<Login />} />
            <Route exact path="signup" element={<Signup />} />
            <Route path="*" element={<Home />} />

        </Routes>
    )
}

export default AppRoutes
